import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

import "../utils/normalize.css"
import "../utils/css/screen.css"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Seo title="Oh no - 404" />
        <div className="post-content">
          <h1>Whoopsies</h1>
          <p>Ich weiß nicht wo du hinwolltest, aber hier gehts definitiv nicht lang.<br /><br />
          <Link to={"/"}>Hier kommst du zurück zur Startseite.</Link></p>
        </div>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
